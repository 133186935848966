<template>
    <div class="params-m">
        <div class="params-m-block">
            <div class="params-m-block-title">{{ this.$t('appearance') }}</div>

            <div class="appearance-imgs">
                <img v-if="current === 'panorama'" :src="require(`@assets/images/params/${buildForOverseas ? 'evelabinsight/' : ''}v1.png`)" alt="" />
                <img v-if="current === 'dimensions'" :src="require(`@assets/images/params/${buildForOverseas ? 'evelabinsight/' : ''}v2.png`)" alt="" />
            </div>
            <div class="appearance-tabs">
                <div class="appearance-tabs-content">
                    <a href="javascript:;" class="poppins appearance-tab" :class="{ active: item.key === current }" @click="onTabClick(item)" v-for="item in tabs" :key="item.key">{{ item.name }}</a>
                </div>
            </div>
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('sizeAndWeight') }}</div>
            <img class="img01" :src="require(`@assets/images/params/${buildForOverseas ? 'evelabinsight/' : ''}v4.png`)" alt="" />

            <paramsTable :data="i18nPage.sizeAndWeightData" />
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('basicInfo') }}</div>

            <paramsTable :data="i18nPage.basicInfoData" />
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('power') }}</div>

            <paramsTable :data="i18nPage.powerData" />
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('camera') }}</div>

            <paramsTable :data="i18nPage.cameraData" />
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('lightSystem') }}</div>

            <paramsTable :data="i18nPage.lightSystemData" />
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('otherAccessories') }}</div>

            <paramsTable :data="i18nPage.otherAccessoriesData" />
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('network') }}</div>

            <paramsTable :data="i18nPage.networkData" />
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('viewSuggest') }}</div>

            <paramsTable :data="i18nPage.viewSuggestData" />
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('models') }}</div>

            <paramsTable :data="i18nPage.modelsData" />
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('quailty') }}</div>

            <paramsTable :data="i18nPage.quailtyData" />
        </div>

        <div class="params-m-block">
            <div class="params-m-block-title">{{ $t('package') }}</div>

            <paramsTable :data="i18nPage.packageData" />
        </div>
    </div>
</template>

<script>
import paramsTable from './comp/paramsTable.vue';
export default {
    components: {
        paramsTable,
    },
    data() {
        return {
            current: 'panorama',
            pageDurationID: 'TechSpecs_MeituEveV',
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    computed: {
        i18nPage() {
            return this.$t('paramsEveVPage');
        },
        tabs() {
            return [
                {
                    name: this.$t('panorama'),
                    key: 'panorama',
                },
                {
                    name: this.$t('dimensions'),
                    key: 'dimensions',
                },
            ];
        },
    },
    methods: {
        onTabClick(data = {}) {
            this.current = data.key;
        },
    },
    mounted() {
        this.$track('Q&A_techspace_imp', {
            tab: 'MeituEveM',
        });
    },
};
</script>

<style lang="scss">
.params-m {
    @media only screen and (min-width: 769px) {
        width: 740px;
        margin: 0 auto;
        padding-bottom: 70px;

        .img01 {
            display: block;
            width: 100%;
            margin: 40px 0;
        }

        &-block {
            padding-bottom: 30px;
            border-bottom: solid 1px #d9d9d9;
            margin-bottom: 30px;
            &-title {
                font-size: 18px;
                line-height: 23px;
                color: #787878;
                margin-bottom: 10px;
            }
        }
        .appearance {
            &-imgs {
                height: 295px;
                position: relative;

                img {
                    position: absolute;
                    width: 100%;
                }
            }
            &-tabs {
                display: flex;
                margin-top: 40px;
                &-content {
                    display: flex;
                    border-radius: 32px;
                    margin: 0 auto;
                    overflow: hidden;
                    border: solid 1px #000;
                }
            }
            &-tab {
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 24px;
                box-sizing: border-box;
                color: #000;
                font-size: 14px;

                &.active {
                    background: #000;
                    color: #fff;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding-bottom: 0.8rem;
        .img01 {
            display: block;
            width: 100%;
            margin: 0.1rem 0 0.3rem 0;
        }

        &-block {
            padding-bottom: 0.18rem;
            margin: 0 0.3rem 0.18rem 0.3rem;
            border-bottom: solid 1px #d9d9d9;

            &:last-of-type {
                border: none;
            }

            &-title {
                font-size: 0.16rem;
                color: #787878;
                margin-bottom: 0.18rem;
            }
        }

        .appearance {
            &-imgs {
                height: 145px;
                position: relative;

                img {
                    position: absolute;
                    width: 100%;
                }
            }
            &-tabs {
                display: flex;
                padding: 0.2rem 0;
                &-content {
                    display: flex;
                    justify-content: center;
                    margin: 0 auto;
                    border: solid 1px #000;
                    border-radius: 0.2rem;
                    overflow: hidden;
                }
            }
            &-tab {
                height: 0.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0.2rem;
                box-sizing: border-box;
                color: #000;
                font-size: 0.14rem;

                &.active {
                    background: #000;
                    color: #fff;
                }
            }
        }
    }
}
</style>
