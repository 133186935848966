<template>
    <div class="qa-download">
        <!-- <div class="qa-download-header">
            <div class="poppins qa-download-title">{{ i18nPage.title }}</div>
        </div> -->
        <manuals></manuals>
        <download></download>
    </div>
</template>

<script>
import manuals from './components/manuals.vue';
import download from './components/download.vue';
export default {
    components: {
        manuals,
        download,
    },
    data() {
        return {
            pageDurationID: 'DownloadCenter',
        };
    },
    computed: {
        i18nPage() {
            return this.$t('downloadPage');
        },
    },
    mounted() {
        this.$track('Q&A_download_imp');

        setTimeout(() => {
            let hash = location.hash || '';
            hash = hash.replace('#', '');
            let el = document.getElementById(hash);
            if (el) {
                let offsetTop = el.offsetTop;
                window.scrollTo(0, offsetTop);
            }
        }, 300);
    },
};
</script>

<style lang="scss">
.qa-download {
    @media only screen and (min-width: 769px) {
        &-header {
            width: 740px;
            margin: 0 auto;
            padding: 64px 0;
            display: flex;
        }
        &-title {
            font-size: 18px;
            line-height: 30px;
            font-weight: 700;
            position: relative;

            &:after {
                position: absolute;
                width: 100%;
                height: 2px;
                background: #00C8C8;
                content: '';
                left: 0;
                bottom: -2px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &-header {
            padding: 0.6rem 0.3rem 0.1rem 0.3rem;
            display: flex;
        }
        &-title {
            font-size: 0.2rem;
            line-height: 0.25rem;
            font-weight: 700;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background: #00C8C8;
                left: 0;
                bottom: -7px;
            }
        }
    }
}
</style>
