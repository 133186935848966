<template>
    <div class="download-eve" id="download">
        <div class="block-title poppins download-eve-title">{{ i18nPage.downloadMTitle }}</div>
        <div class="download-eve-container">
            <div class="download-eve-list">
                <div class="poppins download-eve-list-name">{{ $t('eveV') }}</div>
                <div class="download-eve-list-container">
                    <div class="download-eve-item" :class="{ untablet: !isAndroid && !isIpad, tablet: isAndroid || isIpad }">
                        <div class="download-eve-item-box" @click="download('ios', 'v')">
                            <svg class="svg" :class="{ hide: isShowIpadQr }" v-show="!isShowIpadQr" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M35.326 25.564c-.052-7.724 4.288-7.924 4.483-8.048-2.441-3.569-6.239-4.13-7.59-4.183-3.232-.328-6.306 1.866-7.948 1.866-1.637 0-4.169-1.87-6.848-1.823-3.522.052-6.772 2.042-8.59 5.197-3.66 6.353-.938 15.762 2.631 20.911 1.742 2.522 3.822 5.354 6.553 5.254 2.628-.105 3.622-1.704 6.801-1.704 3.18 0 4.07 1.704 6.853 1.647 2.827-.052 4.621-2.57 6.349-5.102 2.003-2.926 2.827-5.615 2.874-5.758-.062-.029-5.515-3.108-5.573-8.257h.005zM23.995 13.6c0-5.73 4.645-10.38 10.38-10.38 0 5.735-4.65 10.38-10.38 10.38z" fill="#666"/></svg>
                            <div v-show="isShowIpadQrHandle" class="showqr" :class="{ hide: isShowIpadQr }">
                                <div class="showqr-icon"></div>
                            </div>
                            <img :src="ipadVQR" alt="" class="qr" :class="{ show: isShowIpadQr }" />
                        </div>
                        <div class="text">
                            <div class="text1">{{ i18nPage.ipad }}</div>
                            <div class="text2">{{ i18nPage.scanDownload }}</div>
                        </div>
                    </div>
                    <div class="download-eve-item" :class="{ untablet: !isAndroid && !isIpad, tablet: isAndroid || isIpad }">
                        <div class="download-eve-item-box" @click="download('android', 'v')">
                            <svg class="svg" :class="{ hide: isShowAndroidQr }" v-show="!isShowAndroidQr" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M38.57 31.483c-1.359 0-2.458-1.133-2.458-2.527V18.148c0-1.397 1.1-2.528 2.457-2.528 1.358 0 2.458 1.131 2.458 2.528v10.808c0 1.394-1.1 2.527-2.458 2.527zm-6.693 5.333h-1.872c.062.224.098.458.098.701v5.334c0 1.397-1.102 2.527-2.46 2.527-1.356 0-2.458-1.13-2.458-2.527v-5.334c0-.243.036-.477.098-.7h-4.566c.062.223.1.457.1.7v5.334c0 1.397-1.102 2.527-2.46 2.527-1.358 0-2.46-1.13-2.46-2.527v-5.334c0-.243.036-.477.098-.7h-1.872c-1.43 0-2.594-1.197-2.594-2.668V15.62H34.47V34.15c0 1.471-1.161 2.667-2.594 2.667zM17.36 5.51l-.461-.713-.457-.7-1.021-1.577a.428.428 0 0 1 .114-.582.402.402 0 0 1 .569.116l1.095 1.69.461.709.467.719c1.48-.588 3.13-.92 4.873-.92 1.743 0 3.395.332 4.875.92l.467-.72.46-.708 1.096-1.69a.401.401 0 0 1 .57-.116c.186.128.238.39.111.582l-1.021 1.576-.455.701-.463.713c3.48 1.664 5.831 4.817 5.831 8.428H11.53c0-3.61 2.353-6.764 5.83-8.428zm10.557 5.059c.68 0 1.228-.567 1.228-1.264 0-.697-.549-1.261-1.228-1.261-.679 0-1.23.564-1.23 1.261s.551 1.264 1.23 1.264zm-9.832 0c.679 0 1.227-.567 1.227-1.264 0-.697-.548-1.261-1.227-1.261-.68 0-1.23.564-1.23 1.261s.55 1.264 1.23 1.264zM7.43 31.483c-1.358 0-2.458-1.133-2.458-2.528V18.148c0-1.397 1.1-2.528 2.458-2.528s2.46 1.131 2.46 2.528v10.808c0 1.394-1.102 2.527-2.46 2.527z" fill="#666"/></svg>
                            <div v-show="isShowAndroidQrHandle" class="showqr" :class="{ hide: isShowAndroidQr }">
                                <div class="showqr-icon"></div>
                            </div>
                            <img :class="{ show: isShowAndroidQr }" :src="androidVQR" alt="" class="qr" />
                        </div>
                        <div class="text">
                            <div class="text1">{{ i18nPage.android }}</div>
                            <div class="text2">{{ i18nPage.scanDownload }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="download-eve-img v"></div>
        </div>
        <div class="download-eve-container">
            <div class="download-eve-list">
                <div class="poppins download-eve-list-name">{{ $t('eve') }}</div>
                <div class="download-eve-list-container">
                    <div class="download-eve-item" :class="{ untablet: !isAndroid && !isIpad, tablet: isAndroid || isIpad }">
                        <div class="download-eve-item-box" @click="download('ios', 'm')">
                            <svg class="svg" :class="{ hide: isShowIpadQr }" v-show="!isShowIpadQr" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M35.326 25.564c-.052-7.724 4.288-7.924 4.483-8.048-2.441-3.569-6.239-4.13-7.59-4.183-3.232-.328-6.306 1.866-7.948 1.866-1.637 0-4.169-1.87-6.848-1.823-3.522.052-6.772 2.042-8.59 5.197-3.66 6.353-.938 15.762 2.631 20.911 1.742 2.522 3.822 5.354 6.553 5.254 2.628-.105 3.622-1.704 6.801-1.704 3.18 0 4.07 1.704 6.853 1.647 2.827-.052 4.621-2.57 6.349-5.102 2.003-2.926 2.827-5.615 2.874-5.758-.062-.029-5.515-3.108-5.573-8.257h.005zM23.995 13.6c0-5.73 4.645-10.38 10.38-10.38 0 5.735-4.65 10.38-10.38 10.38z" fill="#666"/></svg>
                            <div v-show="isShowIpadQrHandle" class="showqr" :class="{ hide: isShowIpadQr }">
                                <div class="showqr-icon"></div>
                            </div>
                            <img :src="ipadQR" alt="" class="qr" :class="{ show: isShowIpadQr }" />
                        </div>
                        <div class="text">
                            <div class="text1">{{ i18nPage.ipad }}</div>
                            <div class="text2">{{ i18nPage.scanDownload }}</div>
                        </div>
                    </div>
                    <div class="download-eve-item" :class="{ untablet: !isAndroid && !isIpad, tablet: isAndroid || isIpad }">
                        <div class="download-eve-item-box" @click="download('android', 'm')">
                            <svg class="svg" :class="{ hide: isShowAndroidQr }" v-show="!isShowAndroidQr" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M38.57 31.483c-1.359 0-2.458-1.133-2.458-2.527V18.148c0-1.397 1.1-2.528 2.457-2.528 1.358 0 2.458 1.131 2.458 2.528v10.808c0 1.394-1.1 2.527-2.458 2.527zm-6.693 5.333h-1.872c.062.224.098.458.098.701v5.334c0 1.397-1.102 2.527-2.46 2.527-1.356 0-2.458-1.13-2.458-2.527v-5.334c0-.243.036-.477.098-.7h-4.566c.062.223.1.457.1.7v5.334c0 1.397-1.102 2.527-2.46 2.527-1.358 0-2.46-1.13-2.46-2.527v-5.334c0-.243.036-.477.098-.7h-1.872c-1.43 0-2.594-1.197-2.594-2.668V15.62H34.47V34.15c0 1.471-1.161 2.667-2.594 2.667zM17.36 5.51l-.461-.713-.457-.7-1.021-1.577a.428.428 0 0 1 .114-.582.402.402 0 0 1 .569.116l1.095 1.69.461.709.467.719c1.48-.588 3.13-.92 4.873-.92 1.743 0 3.395.332 4.875.92l.467-.72.46-.708 1.096-1.69a.401.401 0 0 1 .57-.116c.186.128.238.39.111.582l-1.021 1.576-.455.701-.463.713c3.48 1.664 5.831 4.817 5.831 8.428H11.53c0-3.61 2.353-6.764 5.83-8.428zm10.557 5.059c.68 0 1.228-.567 1.228-1.264 0-.697-.549-1.261-1.228-1.261-.679 0-1.23.564-1.23 1.261s.551 1.264 1.23 1.264zm-9.832 0c.679 0 1.227-.567 1.227-1.264 0-.697-.548-1.261-1.227-1.261-.68 0-1.23.564-1.23 1.261s.55 1.264 1.23 1.264zM7.43 31.483c-1.358 0-2.458-1.133-2.458-2.528V18.148c0-1.397 1.1-2.528 2.458-2.528s2.46 1.131 2.46 2.528v10.808c0 1.394-1.102 2.527-2.46 2.527z" fill="#666"/></svg>
                            <div v-show="isShowAndroidQrHandle" class="showqr" :class="{ hide: isShowAndroidQr }">
                                <div class="showqr-icon"></div>
                            </div>
                            <img :class="{ show: isShowAndroidQr }" :src="androidQR" alt="" class="qr" />
                        </div>
                        <div class="text">
                            <div class="text1">{{ i18nPage.android }}</div>
                            <div class="text2">{{ i18nPage.scanDownload }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="download-eve-img m"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
const ua = navigator.userAgent.toLowerCase();
export default {
    data() {
        return {
            browserTipImg: 'https://h5.meitu.com/images/android.png',
            isShowBrowserTip: false,
            apkUrl: 'https://meitu-eve.zone1.meitudata.com/MeituEve-android.apk',
            googleUrl: 'https://play.google.com/store/apps/details?id=com.meitu.eve',
            appstoreUrl: 'https://apps.apple.com/cn/app/id1473367193',
            isShowIpadQr: false,
            isShowAndroidQr: false,
            ipadQR: require('@assets/images/download/ipad.png'),
            ipadVQR: require('@assets/images/download/v-app-store.png'),
            isIOS: /(iPhone|iPad|iPod|iOS)/gi.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 2),
            isWechat: /micromessenger/.test(ua),
            isMobile: (/(iPhone|iPad|iPod|iOS|Android|adr|Windows Phone|SymbianOS)/gi.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 2)) && !window.MSStream,
            isAndroid: /android|adr|samsung|xiaomi|miui/gi.test(ua),
            isIpad: (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) || /iPad/gi.test(ua),
            androidQR: require('@assets/images/download/m_apk.png'),
            androidVQR: require('@assets/images/download/v_apk.png'),
            vapkUrl: 'https://meitu-eve.zone1.meitudata.com/MeituEveV-android.apk',
            vappstoreUrl: 'https://apps.apple.com/cn/app/id1593153396',
            vgoogleUrl: '',
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang,
        }),
        i18nPage() {
            return this.$t('downloadPage');
        },
        isShowIpadQrHandle() {
            let { isIpad } = this;
            if (isIpad) {
                return false;
            }
            return true;
        },
        isShowAndroidQrHandle() {
            let { isMobile } = this;
            if (isMobile) return false;
            return true;
        },
    },
    methods: {
        downloadLink(href) {
            let link = document.getElementById('download-link') || document.createElement('a');
            link.download = +new Date();
            link.href = href;
            link.click();
        },
        download(type, app) {
            let { isWechat, isMobile, isIOS } = this;
            var appstoreUrl = this.appstoreUrl;
            var apkUrl = this.apkUrl;
            if (app === 'v') {
                appstoreUrl = this.vappstoreUrl;
                apkUrl = this.vapkUrl;
            }

            if (isMobile) {
                if (isIOS) {
                    if (type === 'ios') {
                        location.href = appstoreUrl;
                    } else {
                        this.downloadLink(apkUrl);
                    }
                } else {
                    if (type === 'ios') {
                        this.isShowIpadQr = true;
                    } else {
                        this.downloadLink(apkUrl);
                    }
                }
            } else {
                if (type === 'ios') {
                    this.isShowIpadQr = true;
                } else {
                    this.isShowAndroidQr = true;
                }
            }
        },
    },
    mounted() {
        console.log('navigator.maxTouchPoints', navigator.maxTouchPoints);
        console.log('navigator.platform', navigator.platform);
        console.log('ua', ua);
        console.log(`isIOS=${this.isIOS}; isWechat=${this.isWechat}; isMobile=${this.isMobile}; isAndroid=${this.isAndroid}; isIpad=${this.isIpad};`);
    },
};
</script>

<style lang="scss">
.download-eve {
    background: #f9f9f9;
    .share-browser-tip {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 99999;
        -webkit-transform: translateZ(0);
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        visibility: hidden;

        &.show {
            opacity: 1;
            visibility: visible;
        }

        img {
            width: 100%;
            position: fixed;
            right: 10px;
            top: 10px;
            z-index: 99999;
            -webkit-transform: translateZ(0);
        }
    }

    .showqr-icon {
        width: 20px;
        height: 20px;
        background: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.895 16.41v-1.327h-1.356v1.327h1.356zm2.023 0a.605.605 0 0 0 .603-.603v-.724h-1.299v1.326l.696.001zM7.19 3.097v3.982H3.208V3.097H7.19zm.724-1.328h-5.43a.605.605 0 0 0-.604.604v5.43c0 .331.272.603.604.603h5.43a.605.605 0 0 0 .603-.603v-5.43a.605.605 0 0 0-.603-.604zM4.535 5.088a.664.664 0 1 0 1.327 0 .664.664 0 0 0-1.327 0zm10.659-1.991v3.982h-3.982V3.097h3.982zm.724-1.328h-5.43a.605.605 0 0 0-.603.604v5.43c0 .331.271.603.603.603h5.43a.605.605 0 0 0 .603-.603v-5.43a.605.605 0 0 0-.603-.604zm-3.379 3.319a.664.664 0 1 0 1.328 0 .664.664 0 0 0-1.328 0zM7.19 11.1v3.982H3.209V11.1H7.19zm.725-1.328h-5.43a.605.605 0 0 0-.604.604v5.43c0 .332.272.603.604.603h5.43a.605.605 0 0 0 .603-.603v-5.43a.605.605 0 0 0-.603-.604zm-3.379 3.319a.664.664 0 1 0 1.327 0 .664.664 0 0 0-1.327 0zm11.383-3.319h-.74v2.655H13.85V9.773h-3.363a.605.605 0 0 0-.603.604v5.43c0 .332.271.603.603.603h.724v-3.982h1.327v1.327h3.982v-3.378a.605.605 0 0 0-.603-.604z' fill='%23fff'/%3E%3C/svg%3E") 0 0 no-repeat;
        background-size: contain;
    }

    &-item {
        &.tablet {
            .download-eve-item-box {
                pointer-events: auto !important;
            }
        }
        &-box {
            pointer-events: auto;
            .svg {
                opacity: 1;
                transform: scale(1);
                transition: all 0.2s ease;
                &.hide {
                    opacity: 0;
                    transform: scale(0);
                }
            }
            .qr {
                opacity: 0;
                transform: scale(0.4);
                transition: all 0.2s ease;
                &.show {
                    opacity: 1;
                    transform: scale(1);
                }
            }
            .showqr {
                opacity: 1;
                transform: scale(1);
                transform-origin: 100% 0;
                transition: all 0.3s ease;
                &.hide {
                    opacity: 0;
                    transform: scale(0);
                }
            }
        }
        .text {
            position: relative;
            .text1 {
                opacity: 1;
                transform: translateY(0);
                transition: all 0.2s ease;
            }
            .text2 {
                width: 100%;
                text-align: center;
                position: absolute;
                opacity: 0;
                // transform: translateY(20px);
                transition: all 0.2s ease;
                pointer-events: none;
            }
        }
    }

    @media only screen and (min-width: 769px) {
        padding-top: 80px;
        padding-bottom: 15px;
        &-title {
            margin-bottom: 40px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 68px;
                height: 2px;
                background: #00C8C8;
                left: 50%;
                bottom: -12px;
                transform: translateX(-50%);
            }
        }
        &-container {
            max-width: 1100px;
            margin: 0 auto;
            padding: 0 2% 80px 2%;
            display: flex;
            align-items: center;
        }
        &-img {
            width: 325px;
            height: 219px;
            margin-left: auto;
            margin-right: 166px;
            margin-top: 50px;

            &.v {
                background: url(~@assets/images/download/v.png) 0 0 no-repeat;
                background-size: contain;
            }
            &.m {
                background: url(~@assets/images/download/m.png) 0 0 no-repeat;
                background-size: contain;
            }
        }
        &-list {
            margin-top: 20px;
            padding-left: 166px;

            &-name {
                font-size: 24px;
                line-height: 29px;
                font-weight: 600;
                margin-bottom: 20px;
            }
            &-container {
                display: flex;
            }
        }
        &-item {
            margin-right: 50px;
            &-box {
                width: 130px;
                height: 130px;
                border-radius: 10px;
                box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06);
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                overflow: hidden;
                .qr {
                    display: block;
                    width: 104px;
                    height: 104px;
                    opacity: 0;
                    // transition: opacity .7s;
                    position: absolute;
                    top: 13px;
                    left: 13px;
                }
                .showqr {
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-style: solid;
                    border-width: 0 50px 50px 0;
                    border-color: transparent #00C8C8 transparent transparent;
                    &-icon {
                        margin-left: 25px;
                        margin-top: 5px;
                    }
                }
            }
            .text {
                font-size: 14px;
                margin-top: 20px;
                position: relative;
                .text1, .text2 {
                    width: 100%;
                    text-align: center;
                }
            }
            .text2 {
                text-align: center;
                font-size: 14px;
                opacity: 0;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 0.6rem 0;

        &-title {
            font-size: 0.2rem;
            font-weight: 600;
            text-align: center;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 67px;
                height: 2px;
                background: #00C8C8;
                left: 50%;
                bottom: -9px;
                transform: translateX(-50%);
            }
        }
        &-container {
            margin: 0 0.22rem;
            border-bottom: solid 1px rgba(0, 0, 0, 0.05);

            &:last-of-type {
                border: none;
            }
        }
        &-list {
            &-name {
                font-size: 0.18rem;
                line-height: 0.23rem;
                margin-bottom: 0.25rem;
                margin-top: 0.5rem;
                text-align: center;
                font-weight: 600;
            }
        }

        &-item {
            margin-bottom: 0.35rem;

            &-box {
                width: 1.6rem;
                height: 1.6rem;
                margin: 0 auto;
                background: #FFFFFF;
                box-shadow: 0px 12px 49px rgba(0, 0, 0, 0.06);
                border-radius: 0.08rem;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                overflow: hidden;

                .qr {
                    display: block;
                    width: 1.28rem;
                    height: 1.28rem;
                    opacity: 0;
                    position: absolute;
                    top: 0.16rem;
                    left: 0.16rem;
                }
                .showqr {
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-style: solid;
                    border-width: 0 50px 50px 0;
                    border-color: transparent #00C8C8 transparent transparent;
                    &-icon {
                        margin-left: 25px;
                        margin-top: 5px;
                    }
                }
            }
            .text {
                font-size: 0.14rem;
                margin-top: 0.15rem;
                text-align: center;
            }
        }
    }

    @media (any-hover: hover) {
        &-item {
            &-box {
                pointer-events: none;
            }
            // 三星平板支持 any-hover: hover
            &.untablet {
                &:hover {
                    .svg {
                        opacity: 0;
                        transform: scale(0);
                    }
                    .qr {
                        opacity: 1;
                        transform: scale(1);
                    }
                    .showqr {
                        opacity: 0;
                        transform: scale(0);
                    }
                    .text {
                        .text1 {
                            opacity: 0;
                            transform: translateY(-20px);
                            transition: all 0.2s ease;
                        }
                        .text2 {
                            opacity: 1;
                            transform: translateY(-20px);
                            transition: all 0.2s ease;
                            pointer-events: auto;
                        }
                    }
                }
            }
        }
    }
}
</style>
