<template>
    <div class="tech-specs-tabs" ref="scroll">
        <router-link class="poppins tech-specs-tab" :class="[`tech-specs-tab-${item.key}`]" :to="{ path: '/' + $route.params.lang + '/qa/tech-specs/' + item.key, query: $route.query }" v-for="item in tabs" :key="item.key" @mousedown.native="onTabClick(item)">
            {{ item.name }}
        </router-link>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('qaTechSpecsPage');
        },
        tabs() {
            var { i18nPage } = this;
            return [
                {
                    name: this.$t('eveV'),
                    key: 'evev',
                    trackId: 'MeituEveV',
                },
                {
                    name: this.$t('eve'),
                    key: 'eve',
                    trackId: 'MeituEveM',
                },
                {
                    name: this.$t('meitukey'),
                    key: 'evekey',
                    trackId: 'MeituKey',
                },
                {
                    name: i18nPage.genius,
                    key: 'genius',
                    trackId: 'MeituGenius',
                },
            ];
        },
    },
    methods: {
        onTabClick(data) {
            if (this.$route.path.indexOf(data.key) < 0) {
                this.$track('Q&A_techspace_tab_clk', {
                    tab: data.trackId,
                });
            }
            this.$nextTick(() => {
                this.mxScrollToCenter({
                    scrollEl: this.$refs.scroll,
                    el: this.$refs.scroll.querySelector(`.tech-specs-tab-${data.key}`),
                });
            });
        },
    },
};
</script>

<style lang="scss">
.tech-specs {
    @media only screen and (min-width: 769px) {
        &-tabs {
            display: flex;
            width: 740px;
            margin: 80px auto;
        }
        &-tab {
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            color: #999;
            position: relative;
            margin: 0 20px 0 0;
            padding: 10px;
            &:after {
                content: '';
                width: 100%;
                height: 2px;
                background: #00C8C8;
                bottom: 0;
                left: 0;
                position: absolute;
                transform: scaleX(0);
            }
            &.router-link-active {
                color: #000;
                // font-size: 18px;
                font-weight: 700;
                &:after {
                    transform: scaleX(1);
                    transition: all 0.3s;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        &-tabs {
            white-space: nowrap;
            overflow-x: auto;
            padding: 0.35rem 0.3rem 0.4rem 0.3rem;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        &-tab {
            font-size: 0.17rem;
            line-height: 0.25rem;
            color: #999;
            font-weight: 400;
            margin-right: 0.2rem;
            position: relative;
            &:last-of-type {
                margin-right: 0;
            }
            &:after {
                content: '';
                width: 100%;
                height: 2px;
                background: #00C8C8;
                bottom: -7px;
                left: 0;
                position: absolute;
                transform: scaleX(0);
            }
            &.router-link-active {
                font-weight: 700;
                color: #000;
                &:after {
                    transform: scaleX(1);
                    transition: all 0.3s;
                }
            }
        }
    }
}
</style>
