<template>
    <div class="params-genius">
        <table class="table">
            <thead class="poppins">
                <tr>
                    <th></th>
                    <th width="120">{{ i18nPage.inStore }}</th>
                    <th width="140">{{ i18nPage.online }}</th>
                    <th width="180">{{ i18nPage.assistant }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div class="desc">{{ i18nPage.arFun }}</div>
                        {{ i18nPage.single }}
                    </td>
                    <td><div class="icon-support"></div></td>
                    <td><div class="icon-support"></div></td>
                    <td><div class="icon-support"></div></td>
                </tr>
                <tr>
                    <td>{{ i18nPage.looks }}</td>
                    <td><div class="icon-support"></div></td>
                    <td><div class="icon-support"></div></td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{ i18nPage.hair }}</td>
                    <td><div class="icon-support"></div></td>
                    <td><div class="icon-support"></div></td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{ i18nPage.accessory }}</td>
                    <td><div class="icon-support"></div></td>
                    <td><div class="icon-support"></div></td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{ i18nPage.contacts }}</td>
                    <td><div class="icon-support"></div></td>
                    <td><div class="icon-support"></div></td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <div class="desc">{{ i18nPage.ai }}</div>
                        {{ i18nPage.facial }}
                    </td>
                    <td><div class="icon-support"></div></td>
                    <td><div class="icon-support"></div></td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{ i18nPage.tone }}</td>
                    <td><div class="icon-support"></div></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <div class="desc">{{ i18nPage.backEnd }}</div>
                        {{ i18nPage.product }}
                    </td>
                    <td><div class="icon-support"></div></td>
                    <td><div class="icon-support"></div></td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{ i18nPage.dataCenter }}</td>
                    <td><div class="icon-support"></div></td>
                    <td><div class="icon-support"></div></td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{ i18nPage.deliver }}</td>
                    <td>{{ i18nPage.app }}</td>
                    <td>{{ i18nPage.miniP }}</td>
                    <td>{{ i18nPage.h5 }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageDurationID: 'TechSpecs_MeituGenius',
        };
    },
    computed: {
        i18nPage() {
            return this.$t('paramsGeniusPage');
        },
    },
    mounted() {
        this.$track('Q&A_techspace_imp', {
            tab: 'MeituGenius',
        });
    },
};
</script>

<style lang="scss">
.params-genius {
    .icon-support {
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M0 0h40v40H0z'/%3E%3Cpath d='M9 19.5l6.793 6.793a1 1 0 0 0 1.414 0L31.5 12' stroke='%2364DDD8' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") 0 0 no-repeat;
        background-size: contain;
    }
    @media only screen and (min-width: 769px) {
        width: 740px;
        margin: 0 auto;
        padding-bottom: 100px;

        thead {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            th {
                text-align: center;
                padding: 0 20px 26px 20px;
                border-right: solid 1px #999;

                &:first-of-type {
                    border: none;
                }
                &:last-of-type {
                    border: none;
                }
            }
        }
        tbody {
            tr {
                border-bottom: solid 1px #999;
                &:last-of-type {
                    border: none;
                }
            }
            td {
                font-size: 14px;
                line-height: 20px;
                padding: 18px 0;
                text-align: center;
                border-right: solid 1px #999;

                .desc {
                    font-size: 12px;
                    line-height: 16px;
                    color: #999;
                }

                &:first-of-type {
                    font-weight: 600;
                    padding-right: 30px;
                    text-align: left;
                    border: none;
                }
                &:last-of-type {
                    border: none;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 0 0.2rem 0.5rem 0.2rem;

        .icon-support {
            width: 0.3rem;
            height: 0.3rem;
        }

        thead {
            font-size: 0.12rem;
            line-height: 0.18rem;
            font-weight: 700;

            th {
                padding: 0 0.05rem 0.3rem 0.05rem;
                text-align: center;

                &:nth-child(2) {
                    padding-left: 0;
                }
            }
        }
        tbody {
            td {
                font-size: 0.12rem;
                line-height: 0.16rem;
                padding: 0.1rem 0;
                border-bottom: solid 1px rgba(153, 153, 153, 0.2);
                text-align: center;

                &:first-of-type {
                    border: none;
                    text-align: left;
                    padding-right: 0.1rem;
                }

                .desc {
                    font-size: 0.1rem;
                    line-height: 1.4;
                    color: #999;
                }
            }
            tr {
                &:last-of-type {
                    td {
                        border: none;
                    }
                }
            }
        }
    }
}
</style>
