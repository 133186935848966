<template>
    <div class="download-manuals">
        <h1 class="block-title poppins download-manuals-title">{{ i18nPage.manualsTitle }}</h1>
        <div class="download-manuals-container">
            <div class="download-manuals-item">
                <div class="download-manuals-item-img">
                    <img class="evem" :src="require('@assets/images/download/evelabinsight/eve-v.png')" alt="" v-if="buildForOverseas" />
                    <img class="evem" :src="require('@assets/images/download/eve-v.png')" alt="" v-else />
                </div>
                <div class="poppins download-manuals-item-name">{{ i18nPage.eveV }}</div>
                <div class="download-manuals-item-list">
                    <a href="javascript:;" class="link" @click="showModal(item)" v-for="(item, index) in vList" :key="index">
                        {{ item.name }}
                        <span class="icon-right"></span>
                    </a>
                </div>
            </div>

            <div class="download-manuals-item">
                <div class="download-manuals-item-img">
                    <img class="evem" :src="require('@assets/images/download/evelabinsight/eve-m.png')" alt="" v-if="buildForOverseas" />
                    <img class="evem" :src="require('@assets/images/download/eve-m.png')" alt="" v-else />
                </div>
                <div class="poppins download-manuals-item-name">{{ i18nPage.eveM }}</div>
                <div class="download-manuals-item-list">
                    <a href="javascript:;" class="link" @click="showModal(item)" v-for="(item, index) in mList" :key="index">
                        {{ item.name }}
                        <span class="icon-right"></span>
                    </a>
                </div>
            </div>

            <div class="download-manuals-item">
                <div class="download-manuals-item-img">
                    <img class="meitukey" :src="require('@assets/images/download/evelabinsight/meitukey.png')" alt="" v-if="buildForOverseas" />
                    <img class="meitukey" :src="require('@assets/images/download/meitukey.png')" alt="" v-else />
                </div>
                <div class="poppins download-manuals-item-name">{{ i18nPage.meitukey }}</div>
                <div class="download-manuals-item-list meitukey">
                    <router-link :to="{ path: '/' + $route.params.lang + '/qa/evekey', query: $route.query }" class="link" @click.native="onMeitukeyClick">
                        {{ i18nPage.userManual }}
                        <span class="icon-right"></span>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="download-manuals-modal" :class="{ show: isShowModal }">
            <div class="mask" @click="closeModal"></div>
            <div class="content">
                <svg class="close" @click="closeModal" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l12.728 12.728M14 1L1.272 13.728" stroke="#DCDCDC" stroke-width="2" stroke-linecap="round"/></svg>
                <div class="poppins title">{{ i18nPage.enter_device }}</div>
                <div class="desc">{{ i18nPage.device_desc }}</div>
                <input class="ipt" type="text" :placeholder="i18nPage.please_enter" v-model="device_id" />
                <div class="desc2">{{ i18nPage.device_ph }}</div>
                <a href="javascript:;" class="poppins submit" @click="getManuals">{{ i18nPage.sure }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import http from '@utils/http';
import iNoBounce from '@utils/inobounce';
export default {
    data() {
        return {
            isShowModal: false,
            device_id: '',
            manualsUrl: '',
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang,
        }),
        i18nPage() {
            return this.$t('downloadPage');
        },
        vList() {
            let { i18nPage } = this;
            let lang = this.currentLang;
            // eveM 帮助中心支持的语言
            let langArr = ['zh', 'tw', 'en', 'ko', 'ja'];
            if (langArr.indexOf(lang) < 0) {
                lang = 'en'; // 默认英文
            }
            let host = `https://h5.meitu.com/eve/2.0/help/?lang=${lang}&spm=official`;

            return [
                {
                    name: i18nPage.v1,
                    href: `${host}#index=0&key=0&route=use`,
                    trackId: 1,
                },
                {
                    name: i18nPage.v2,
                    href: `${host}#index=1&key=1&route=use`,
                    trackId: 2,
                },
                {
                    name: i18nPage.v3,
                    href: `${host}#index=2&key=2&route=use`,
                    trackId: 3,
                },
                {
                    name: i18nPage.v4,
                    href: `${host}#index=3&key=3&route=use`,
                    trackId: 4,
                },
                {
                    name: i18nPage.v5,
                    href: `${host}#index=4&key=4&route=use`,
                    trackId: 5,
                },
                {
                    name: i18nPage.v6,
                    href: `${host}#index=5&key=5&route=use`,
                    trackId: 6,
                },
            ];
        },
        mList() {
            let { i18nPage } = this;
            let lang = this.currentLang;
            // eveM 帮助中心支持的语言
            let langArr = ['zh', 'tw', 'en', 'ko', 'ja'];
            if (langArr.indexOf(lang) < 0) {
                lang = 'en'; // 默认英文
            }
            let host = `https://h5.meitu.com/eve/help/?lang=${lang}&spm=official`;
            return [
                {
                    name: i18nPage.unboxing,
                    href: `${host}#index=0&key=0&route=use`,
                    trackId: 1,
                },
                {
                    name: i18nPage.installation,
                    href: `${host}#index=1&key=1&route=use`,
                    trackId: 4,
                },
                {
                    name: i18nPage.loginConnect,
                    href: `${host}#index=2&key=2&route=use`,
                    trackId: 5,
                },
                {
                    name: i18nPage.detection,
                    href: `${host}#index=3&key=3&route=use`,
                    trackId: 7,
                },
            ];
        },
    },
    methods: {
        closeModal() {
            this.isShowModal = false;
            iNoBounce.disable();
        },
        showModal(data) {
            this.manualsUrl = data.href;
            this.isShowModal = true;
            iNoBounce.enable();
            this.$track('Q&A_download_clk', {
                track_id: data.trackId,
            });
        },
        onMeitukeyClick() {
            this.$track('Q&A_download_clk', {
                track_id: 9,
            });
        },
        getManuals() {
            if (!this.device_id) {
                this.$toast.center(this.i18nPage.enter_device);
                return;
            }
            let api = `${process.env.VUE_APP_API_HOST}h5/manuals`;
            let data = {
                device_id: this.device_id,
                type: 'software',
                client_language: this.currentLang,
            };
            http.get(api, {
                params: data,
            })
                .then(res => {
                    res = res.data;
                    if (res.meta && res.meta.code === 0) {
                        this.closeModal();
                        window.open(this.manualsUrl, '_blank');
                    } else if (res.meta && res.meta.code === 400) {
                        this.$toast.center(this.i18nPage.no_device);
                    } else {
                        this.$toast.center(this.$t('error_try_again'));
                    }
                    console.log(res);
                })
                .catch(err => {
                    console.error(err);
                });
        },
    },
};
</script>

<style lang="scss">
.download-manuals {
    .icon-right {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 6px;
        border-color: transparent transparent transparent #000;
    }
    &-modal {
        opacity: 0;
        visibility: hidden;
        .mask {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
            z-index: 1200;
            background-color: rgba(0, 0, 0, 0.6);
            // opacity: 0;
            transition: all 0.35s;
        }
        .content {
            position: fixed;
            top: 50%;
            left: 50%;
            z-index: 1210;
            transform: translate(-50%, 0%);
            background: #fff;
            max-width: 3.37rem;
            box-sizing: border-box;
            opacity: 0;
            transition: all 0.3s;
            border-radius: 10px;
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
            padding: 80px 40px 40px 40px;
            .close {
                position: absolute;
                top: 24px;
                right: 24px;
                cursor: pointer;
            }
            .title {
                font-size: 40px;
                font-weight: 600;
                line-height: 48px;
                width: 380px;
                margin: 0 auto;
                text-align: center;
            }
            .desc {
                font-size: 14px;
                line-height: 24px;
                margin-top: 15px;
                margin-bottom: 50px;
            }
            .desc2 {
                width: 390px;
                font-size: 12px;
                color: #999;
                margin: 4px auto 50px auto;
            }
            .ipt {
                width: 370px;
                border-radius: 5px;
                padding: 10px;
                border: solid 1px #d9d9d9;
                margin: 0 auto;
                display: block;
            }
            .submit {
                width: 140px;
                height: 36px;
                border: 1.5px solid #00C8C8;
                border-radius: 18px;
                font-size: 16px;
                font-weight: 700;
                color: #00C8C8;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
            }
        }
        &.show {
            opacity: 1;
            visibility: visible;
            .content {
                opacity: 1;
                transform: translate(-50%, -50%);
            }
        }
    }
    @media only screen and (min-width: 769px) {
        padding: 80px 0;
        width: 1080px;
        margin: 0 auto;

        &-title {
            position: relative;

            &:after {
                content: '';
                width: 67px;
                height: 2px;
                background: #00C8C8;
                position: absolute;
                left: 50%;
                bottom: -12px;
                transform: translateX(-50%);
            }
        }

        &-container {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
        }

        &-item {
            width: 325px;
            min-height: 358px;
            box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            background: #fff;
            display: flex;
            flex-direction: column;
            padding-bottom: 15px;

            &-img {
                height: 160px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    display: block;
                    height: 120px;
                    &.meitukey {
                        height: 106px;
                        margin-top: 20px;
                    }
                }
            }
            &-name {
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 14px;
            }

            &-list {
                display: flex;
                padding: 0 30px;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;

                &.meitukey {
                    justify-content: center;

                    .link {
                        justify-content: center;
                    }
                }

                .link {
                    flex: 1;
                    box-sizing: border-box;
                    font-size: 14px;
                    line-height: 20px;
                    color: #000;
                    margin: 7px 0;
                    display: flex;
                    align-items: center;

                    .icon-right {
                        margin-left: 6px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0.5rem 0;
        &-title {
            font-size: 0.2rem;
            font-weight: 600;
            text-align: center;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 67px;
                height: 2px;
                background: #00C8C8;
                left: 50%;
                bottom: -9px;
                transform: translateX(-50%);
            }
        }
        &-container {
            padding: 0 0.2rem;
        }
        &-item {
            background: #fff;
            box-shadow: 0px 6.7px 26.8px rgba(0, 0, 0, 0.06);
            border-radius: 0.12rem;
            margin-top: 0.25rem;
            padding-bottom: 0.2rem;

            &-img {
                height: 1.22rem;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    display: block;
                    max-height: 1rem;
                }
            }
            &-name {
                font-size: 0.2rem;
                font-weight: 600;
                text-align: center;
                padding-bottom: 0.1rem;
            }
            &-list {
                padding: 0 0.3rem;
                display: flex;
                flex-direction: column;

                .link {
                    text-align: center;
                    justify-content: center;
                    font-size: 0.13rem;
                    line-height: 0.18rem;
                    color: #000;
                    margin: 0.05rem 0;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;

                    .icon-right {
                        margin-left: 4px;
                        transform: scale(0.6);
                    }
                }

                &.meitukey {
                    .link {
                        flex: 1;
                        justify-content: center;
                    }
                }
            }
        }

        &-modal {
            .content {
                padding: 0.6rem 0.28rem 0.4rem 0.28rem;
                .title {
                    font-size: 0.28rem;
                    line-height: 0.38rem;
                    width: auto;
                }
                .desc {
                    font-size: 0.13rem;
                    line-height: 0.18rem;
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 0.3rem;
                }
                .ipt {
                    width: 2.85rem;
                    padding: 14px;
                    box-sizing: border-box;
                }
                .desc2 {
                    width: 100%;
                    margin: 0.05rem auto 0.3rem auto;
                }
            }
        }
    }
}
</style>
