<template>
    <div class="params-meitukey">
        <img :src="img" alt="" class="img01" />
        <div class="poppins params-meitukey-title">{{ i18nPage.title }}</div>

        <paramsTable class="table" :data="i18nPage.data" />
    </div>
</template>

<script>
import paramsTable from './comp/paramsTable.vue';
export default {
    components: {
        paramsTable,
    },
    data() {
        return {
            pageDurationID: 'TechSpecs_MeituKey',
        };
    },
    computed: {
        i18nPage() {
            return this.$t('paramsMeitukeyPage');
        },
        img() {
            const buildForOverseas = process.env.VUE_APP_BUILD_FOR === 'evelabinsight';
            if (this.currentLang === 'zh') {
                return require('@assets/images/params/meitukey_zh.png');
            }
            return require(`@assets/images/params/${buildForOverseas ? 'evelabinsight/' : ''}meitukey_en.png`);
        },
    },
    mounted() {
        this.$track('Q&A_techspace_imp', {
            tab: 'MeituKey',
        });
    },
};
</script>

<style lang="scss">
.params-meitukey {
    .img01 {
        display: block;
    }
    @media only screen and (min-width: 769px) {
        padding-bottom: 90px;
        .img01 {
            width: 588px;
            margin: 0 auto;
        }
        &-title {
            font-size: 28px;
            font-weight: 700;
            text-align: center;
            margin-top: 100px;
            margin-bottom: 40px;
        }
        .table {
            width: 588px;
            margin: 0 auto;

            td.label {
                width: 262px;
                padding-left: 30px;
            }
            td.value {
                padding-left: 30px;
                .desc {
                    font-size: 12px;
                    line-height: 20px;
                    color: #999;
                    margin: 10px 0 30px 0;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 0 0.3rem 0.6rem 0.3rem;
        .img01 {
            width: 2.9rem;
            margin: 0 auto;
        }
        &-title {
            font-size: 0.2rem;
            font-weight: 600;
            text-align: center;
            margin-top: 0.6rem;
            margin-bottom: 0.24rem;
        }
        .table {
            .desc {
                color: #999;
                font-size: 0.1rem;
                line-height: 1.4;
            }
        }
    }
}
</style>
