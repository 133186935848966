<template>
    <div class="qa-info">
        <div class="qa-info-header">
            <div class="qa-info-header-tabs" ref="scroll">
                <a href="javascript:;" class="poppins qa-info-header-tab" :class="[{ active: current === item.key }, `qa-info-tab-${item.key}`]" v-for="item in tabs" :key="item.key" @click="onTabClick(item)">
                    {{ item.name }}
                </a>
            </div>
            <div class="qa-info-header-search">
                <input type="search" v-model="keyword" :placeholder="i18nPage.searchPH" />
                <svg @click="onSearch" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10.879" cy="10.399" r="6.5" transform="rotate(-45 10.879 10.4)" stroke="#000" />
                    <path d="M15.828 15.35l4.243 4.242" stroke="#000" stroke-linecap="round" />
                </svg>
            </div>
        </div>

        <div class="qa-info-list">
            <div class="qa-info-list-nodata" v-if="!data.length">
                {{ $t('searchNoDataX', [keyword2]) }}
            </div>
            <div class="qa-info-item" v-for="(item, index) in data" :key="index">
                <div class="qa-info-item-q" @click="onQClick(index)">
                    <div class="icon" :class="{ expand: item.visible }"></div>
                    {{ item.q }}
                </div>
                <transition v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:before-leave="beforeLeave" v-on:leave="leave">
                    <div class="qa-info-item-a" v-if="item.visible">
                        <div class="qa-info-item-a-content" v-html="item.a"></div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'qaInfo',
    data() {
        return {
            pageDurationID: 'FAQs',
            current: 'pre',
            keyword: '',
            keyword2: '',
            data: [],
        };
    },
    computed: {
        i18nPage() {
            return this.$t('qaInfoPage');
        },
        tabs() {
            var { i18nPage } = this;
            return [
                {
                    name: i18nPage.preSales,
                    key: 'pre',
                },
                {
                    name: i18nPage.afterSales,
                    key: 'after',
                },
            ];
        },
    },
    methods: {
        genData() {
            var { current, i18nPage, keyword } = this;
            var data = i18nPage[current];
            data = JSON.parse(JSON.stringify(data));
            if (keyword) {
                keyword = keyword.toLowerCase();
                data = data.filter(item => {
                    var q = item.q.toLowerCase();
                    var a = item.a.toLowerCase().replace(/\<br\>/g, '');

                    return q.indexOf(keyword) >= 0 || a.indexOf(keyword) >= 0;
                });
            }
            this.data = data;
        },
        onSearch() {
            this.$track('Q&A_FAQs_search_clk', {
                content: this.keyword,
            });
            this.keyword2 = this.keyword;
            this.genData();
        },
        onQClick(index) {
            this.$set(this.data[index], 'visible', !this.data[index].visible);
        },
        onTabClick(data = {}) {
            if (this.current === data.key) return;
            this.current = data.key;
            this.$track('Q&A_FAQs_tab_clk', {
                tab: data.key === 'pre' ? 'Pre-sales' : 'After-sales',
            });
            this.genData();
            this.$nextTick(() => {
                this.mxScrollToCenter({
                    scrollEl: this.$refs.scroll,
                    el: this.$refs.scroll.querySelector(`.qa-info-tab-${data.key}`),
                });
            });
        },
        beforeEnter(el) {
            el.style.height = '0';
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px';
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px';
        },
        leave(el) {
            el.style.height = 0;
        },
    },
    mounted() {
        this.genData();
        this.$track('Q&A_FAQs_imp');

        var head = document.getElementsByTagName('head')[0];
        var mainEntity = [];
        var faqArr = [].concat(this.$t('qaInfoPage.pre')).concat(this.$t('qaInfoPage.after'));
        faqArr.forEach(faq => {
            mainEntity.push({
                '@type': 'Question',
                name: faq.q,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: faq.a.replace(/<br>/g, ' '),
                },
            });
        });
        var faqJson = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity,
        };
        var faqSchema = document.getElementById('faqSchema');
        if (!faqSchema) {
            faqSchema = document.createElement('script');
            faqSchema.id = 'faqSchema';
            faqSchema.type = 'application/ld+json';
            head.appendChild(faqSchema);
        }
        faqSchema.text = JSON.stringify(faqJson);
    },
};
</script>

<style lang="scss">
.qa-info {
    input {
        &::-webkit-search-cancel-button {
            display: none;
        }
    }
    @media only screen and (min-width: 769px) {
        width: 840px;
        margin: 0 auto;
        &-header {
            display: flex;
            align-items: center;
            padding: 80px 0 45px 0;

            &-tabs {
                display: flex;
            }
            &-tab {
                font-size: 18px;
                line-height: 27px;
                font-weight: 500;
                padding: 10px;
                color: #999;
                position: relative;
                margin: 0 20px 0 0;

                &:after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #00C8C8;
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    transform: scaleX(0);
                }

                &.active {
                    color: #000;
                    // font-size: 18px;
                    font-weight: 700;
                    &:after {
                        transform: scaleX(1);
                        transition: all 0.3s;
                    }
                }
            }
            &-search {
                width: 258px;
                height: 35px;
                border: solid 1px #d4d4d4;
                border-radius: 5px;
                margin-left: auto;
                display: flex;
                align-items: center;

                input {
                    border: none;
                    outline: none;
                    flex: 1;
                    padding: 0 6px;
                }

                svg {
                    margin: 0 10px;
                    cursor: pointer;
                }
            }
        }
        &-list {
            padding-bottom: 80px;

            &-nodata {
                text-align: center;
                padding: 100px 0;
            }
        }
        &-item {
            border-bottom: solid 1px rgba(0, 0, 0, 0.15);
            margin-bottom: 30px;
            &-q {
                cursor: pointer;
                display: flex;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                margin-bottom: 15px;

                .icon {
                    width: 11px;
                    height: 11px;
                    position: relative;
                    margin-right: 20px;
                    margin-top: 5px;
                    flex-shrink: 0;

                    &:before,
                    &:after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background: #00C8C8;
                        position: absolute;
                        left: 0;
                        top: 5px;
                        transition: all 0.4s;
                    }
                    &:after {
                        transform: rotate(90deg);
                    }
                    &.expand:after {
                        transform: rotate(180deg);
                    }
                }
            }
            &-a {
                transition: height 0.3s;
                overflow: hidden;

                &-content {
                    font-size: 14px;
                    line-height: 22px;
                    padding-bottom: 40px;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        &-header {
            padding: 0.35rem 0;

            &-tabs {
                white-space: nowrap;
                overflow-x: auto;
                padding: 0 0.3rem 0.2rem 0.3rem;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            &-tab {
                font-size: 0.17rem;
                line-height: 0.25rem;
                color: #999;
                font-weight: 400;
                margin-right: 0.2rem;
                position: relative;

                &:last-of-type {
                    margin-right: 0;
                }

                &:after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #00C8C8;
                    bottom: -7px;
                    left: 0;
                    position: absolute;
                    transform: scaleX(0);
                }

                &.active {
                    font-weight: 700;
                    color: #000;
                    &:after {
                        transform: scaleX(1);
                        transition: all 0.3s;
                    }
                }
            }

            &-search {
                width: 2.6rem;
                height: 0.35rem;
                border: solid 1px #d4d4d4;
                border-radius: 5px;
                margin: 0.15rem auto 0 auto;
                display: flex;
                align-items: center;

                input {
                    flex: 1;
                    border: none;
                    outline: none;
                    padding: 0 5px;
                    font-size: 0.13rem;
                    background: #fff;
                }
                svg {
                    margin: 0 0.1rem;
                }
            }
        }

        &-list {
            padding: 0 0.35rem 0.8rem 0.35rem;
            &-nodata {
                text-align: center;
                padding: 1rem 0;
            }
        }
        &-item {
            border-bottom: solid 1px rgba(0, 0, 0, 0.15);
            margin-bottom: 0.2rem;

            &-q {
                display: flex;
                font-size: 0.13rem;
                font-weight: 600;
                line-height: 0.22rem;
                margin-bottom: 0.2rem;

                .icon {
                    width: 11px;
                    height: 11px;
                    position: relative;
                    margin-right: 20px;
                    margin-top: 5px;
                    flex-shrink: 0;

                    &:before,
                    &:after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background: #00C8C8;
                        position: absolute;
                        left: 0;
                        top: 5px;
                        transition: all 0.4s;
                    }
                    &:after {
                        transform: rotate(90deg);
                    }
                    &.expand:after {
                        transform: rotate(180deg);
                    }
                }
            }
            &-a {
                font-size: 0.13rem;
                line-height: 0.2rem;
                transition: height 0.3s;
                overflow: hidden;

                &-content {
                    padding-left: 30px;
                    padding-bottom: 0.3rem;
                }
            }
        }
    }
}
</style>
