<template>
    <table class="params-table">
        <tbody>
            <tr v-for="(item, index) in data" :key="index">
                <td class="label">{{ item.key }}</td>
                <td class="value">
                    <div class="content" v-if="isArray(item.value)">
                        <div class="content-item" v-for="(i, k) in item.value" :key="k">{{ i }}</div>
                    </div>
                    <div class="content" v-html="item.value" v-else></div>
                    <div class="desc" v-html="item.desc" v-if="item.desc"></div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        isArray(data) {
            return Object.prototype.toString.call(data) === '[object Array]';
        },
    },
};
</script>

<style lang="scss">
.params-table {
    @media only screen and (min-width: 769px) {
        width: 100%;
        font-size: 14px;
        line-height: 23px;
        color: #000;
        td {
            padding: 6px 0;
            vertical-align: top;
        }
        .label {
            padding-left: 140px;
            width: 262px;
        }
        .value {
            padding-left: 100px;
        }
        .content {
            &-item {
                padding: 6px 0;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        td {
            font-size: 13px;
            line-height: 24px;
            padding: 6px 0;
            vertical-align: top;
        }
        .label {
            width: 1.5rem;
            padding-right: 0.3rem;
        }
    }
}
</style>
